@use 'node_modules/@angular/material' as mat;
@import 'node_modules/@angular/material/theming';

@include mat.core();

$primary: (
  50: #e1e9f1,
  100: #b5c8db,
  200: #84a4c3,
  300: #527fab,
  400: #2d6399,
  500: #084887,
  600: #07417f,
  700: #063874,
  800: #04306a,
  900: #022157,
  A100: #89aaff,
  A200: #5685ff,
  A400: #2361ff,
  A700: #094fff,
  contrast: (
    50: black,
    100: black,
    200: black,
    300: white,
    400: white,
    500: white,
    600: white,
    700: white,
    800: white,
    900: white,
    A100: black,
    A200: black,
    A400: white,
    A700: white
  )
);

$app-primary: mat.define-palette($primary, 600);
$app-accent: mat.define-palette($primary, 500);

$app-theme: mat.define-light-theme($app-primary, $app-accent, mat.define-palette(mat.$red-palette, 600));

@include mat.all-component-themes($app-theme);

$app-typography: mat.define-typography-config(
  // $font-family: '"BPreplay", "Helvetica Neue", sans-serif'
  $font-family: '"Open Sans", sans-serif'
);

@include mat.all-component-typographies($app-typography);

$background: map-get($app-theme, background);

.mat-body p, .mat-body-1 p, .mat-typography p {
  margin-bottom: 0 !important;
  margin-right: 0 !important;
  margin-top: 0 !important;
}

.mat-button, .mat-raised-button, .mat-icon-button, .mat-stroked-button, .mat-flat-button, .mat-fab, .mat-mini-fab {
  font-weight: 900;
}

.mat-cell, .mat-footer-cell, .mat-header-cell {
  text-align: center !important;
}

.mat-checkbox-label {
  color: var(--swatch3);
}

.mat-dialog-actions {
  justify-content: flex-end;
}

.mat-dialog-actions button:first-child {
  margin-right: 5px;
}

.mat-dialog-actions button:last-child {
  margin-left: 5px;
}

.mat-dialog-actions button:not(:first-child):not(:last-child) {
  margin: 0 5px;
}

.mat-dialog-title {
  padding: 10px 20px;
  align-items: baseline;
  display: flex;
  justify-content: space-between;
}

.mat-divider {
  border-top-color: var(--swatch6);
  border-top-width: 2px !important;
  margin-top: 5px !important;
}

.mat-horizontal-content-container,
.mat-horizontal-stepper-header {
  padding: 0 !important;
}

.mat-horizontal-stepper-content {
  > * {
    width: 100%;
  }
}

.mat-horizontal-stepper-header-container {
  pointer-events: none;
}

.mat-icon {
  vertical-align: middle;
}

.mat-icon-button {
  padding: 0;
  min-width: 0;
  width: 40px;
  height: 40px;
  flex-shrink: 0;
  line-height: 40px;
  border-radius: 50%;
}

.mat-icon-button:hover {
  background: mat.get-color-from-palette($background, 'hover');
}

.mat-list {
  padding-top: 0 !important;
}

.mat-list .mat-list-item {
  height: auto !important;
  padding-top: 10px !important;
}

.mat-list .mat-list-item .mat-list-item-content {
  padding: 0 !important;
}

.mat-row:hover {
  background-color: var(--swatch1);
  cursor: pointer;

  .mat-cell,
  .mat-icon-button,
  .mat-select-arrow,
  .mat-select-value {
    color: var(--swatch4) !important;
  }

  .mat-checkbox-background {
    background-color: var(--swatch2);
  }

  .mat-form-field-appearance-legacy .mat-form-field-underline {
    background-color: var(--swatch4);
  }
}

.mat-sort-header-container {
  justify-content: center;
}

.mat-step-header.cdk-keyboard-focused,
.mat-step-header.cdk-program-focused,
.mat-step-header:hover {
  background-color: transparent !important;
}

.mat-step-label {
  font-weight: 900;
}

.mat-stepper-horizontal {
  background-color: transparent;
}

.mat-stepper-horizontal-line {
  margin: 0 !important;
}

.mat-tab-body-wrapper {
  margin-top: 10px;
}
