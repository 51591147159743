@font-face {
    font-family: 'BPreplay';
    src: url('./assets/fonts/BPreplay/BPreplay.eot');
    src: url('./assets/fonts/BPreplay/BPreplay.eot?#iefix') format('embedded-opentype'),
    url('./assets/fonts/BPreplay/BPreplay.woff') format('woff'),
    url('./assets/fonts/BPreplay/BPreplay.ttf') format('truetype'),
    url('./assets/fonts/BPreplay/BPreplay.svg#Flaticon') format('svg');
  }

  // @import '../node_modules/angular-calendar/css/angular-calendar.css';

  :root {
    --swatch1: #1F4E3Dff;
    --swatch2: #49A760ff;
    --swatch3: #1b281b;
    --swatch4: #ffffff;
    --swatch5: #f0f5f9;
    --swatch6: #c9d6df;
    --swatch7: #7a7a7a;
    --swatch8: #2eb872;
    --swatch9: #b40202;
  }

  a {
    text-decoration: none;
  }

  .button1 {
    background-color: var(--swatch1) !important;
    color: var(--swatch4) !important;
  }

  .button2 {
    background-color: var(--swatch2) !important;
    color: var(--swatch4) !important;
  }

  .button3 {
    background-color: var(--swatch7) !important;
    color: var(--swatch4) !important;
  }

  .action-button {
    @extend .button2;
    margin-left: 10px !important;
  }

  app-editor,
  .embedded-table,
  .full-form-field {
    width: 100% !important;
  }

  app-editor {
    margin-top: 10px;
  }

  body {
    color: var(--swatch3);
    cursor: default;
    //font-family: 'BPreplay', sans-serif;
    font-family: 'Open Sans', sans-serif;
    font-size: 14px;
    height: 100%;
    margin: 0;
  }
  ::-webkit-scrollbar {
    width: 5px;
    height: 5px ;
  }

  /* Track */
  ::-webkit-scrollbar-track {
    box-shadow: inset 0 0 2px grey;
    border-radius: 5px;
  }

  /* Handle */
  ::-webkit-scrollbar-thumb {
    background: var(--swatch2);
    border-radius: 5px;
  }

  /* Handle on hover */
  ::-webkit-scrollbar-thumb:hover {
    background: var(--swatch2);
  }

  .buttons-section {
    margin-top: 10px;
    width: 100%;

    button:last-child, .mat-button-toggle-group {
      float: right;
    }
  }

  .card {
    border: 2px solid var(--swatch6);
    display: flex;
    flex-direction: column;
    padding: 10px;

    .buttons-section {
      width: 69%;
    }
  }

  .card-title {
    font-size: 16px;
    font-weight: 900;
  }

  .container {
    display: flex;
    place-content: space-between;
  }

  .disabled-input {
    border: 2px solid var(--swatch6);
    background-color: var(--swatch5);
    border-radius: 6px;
    height: 32px;
    padding: 2px 0;
    text-indent: 10px;
    width: calc(100% - 4px);
  }

  .fields-section {
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    place-content: space-between;
    padding-top: 15px;

    .field {
      margin-bottom: 10px;
      width: 32%;
    }
  }

  .filter-section {
    align-items: center;
    display: flex;
    flex-direction: row;
    justify-content: space-between;

    .mat-form-field {
      width: 60%;
    }
  }

  form {
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    place-content: space-between;

    app-select-group,
    mat-form-field {
      align-self: flex-end;
      margin-top: 5px;
      width: 32%;
    }
  }

  .form-card {
    @extend .card;
    display: flex;
    flex-direction: row;
    place-content: space-between;
  }

  .form-container {
    display: flex;
    flex-direction: row;
    place-content: space-between;
  }

  .form-card,
  .form-container {
    .form-section {
      width: 69%;
    }

    .info-section {
      width: 29%;
    }

    .mat-divider {
      margin-bottom: 5px !important;
    }
  }

  .form-checkbox {
    align-items: center;
    display: flex;
    margin-bottom: 3px;
    width: 32%;
  }

  html {
    background-color: var(--swatch4);
    height: 100%;
  }

  .info-message {
    font-size: 16px;
    font-weight: 900;
    margin-top: 20px;
    text-align: center;
  }

  input {
    color: var(--swatch3);
    //font-family: 'BPreplay', sans-serif;
    font-family: 'Open Sans', sans-serif;
    font-size: 14px;
  }

  input:focus {
    border-color: var(--swatch1);
    outline: none;
  }

  .keywords-container {
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    margin-bottom: 10px;

    .keyword-item {
      border: 2px solid var(--swatch2);
      border-radius: 20px;
      margin-right: 10px;
      margin-top: 10px;
      padding: 8px;
      transition: all 0.5s ease;
    }
  }

  label {
    color: var(--swatch1);
    display: inherit;
    font-weight: 600;
    margin-bottom: 5px;
  }

  .message-section {
    margin-top: 10px;
  }

  .middle-form-field {
    margin-right: 34%;
  }

  .ngx-org-management {
    background-color: var(--swatch1);
    border-color: var(--swatch1);
    color: var(--swatch4);
  }

  .ngx-org-name {
    //font-family: 'BPreplay', sans-serif !important;
    font-family: 'Open Sans', sans-serif !important;
    font-weight: 900;
    text-align: center;
  }

  .ngx-org-title {
    //font-family: 'BPreplay', sans-serif !important;
    font-family: 'Open Sans', sans-serif !important;
    white-space: pre-line;
  }

  p {
    margin-block-start: 0;
    margin-block-end: 0;
    margin-inline-start: 0;
    margin-inline-end: 0;
  }

  .ql-container {
    height: 385px;
    margin-bottom: 10px;
  }

  .right-section {
    width: 32%;

    > * > div {
      margin-bottom: 20px;
    }
  }

  .side-panel {
    display: flex;
    gap: 10px;
  }

  .settings-container {
    align-items: flex-start;
    display: flex;
    flex-direction: row;

    .main-container {
      flex: 1;
      margin-left: 20px;
    }

    .models-panel {
      width: 20%;

      .active-model {
        background-color: var(--swatch1);
        color: var(--swatch4);
      }

      .model-element {
        align-items: center;
        display: flex;
        font-weight: 900;
        min-height: 50px;
        padding-left: 20px;
        transition: background-color 0.5s ease;
      }

      .model-element:hover {
        background-color: var(--swatch1);
        color: var(--swatch4);
        cursor: pointer;
      }
    }
  }

  .spacer {
    flex: 1 1 auto;
  }

  .details-header{
    align-items: center;
    display: flex;
    flex-direction: row;
    margin-top: 10px;

    .failed {
      @extend .status;
      background-color: var(--swatch9);
    }

    .name {
      color: var(--swatch7);
      font-style: italic;
      margin-left: 10px;
    }

    .status {
      border-radius: 20px;
      color: var(--swatch4);
      margin: 0 10px !important;
      padding: 10px;
    }

    .success {
      @extend .status;
      background-color: var(--swatch8);
    }

    .warning {
      @extend .status;
      background-color: var(--swatch7);
    }

    .subtitle-label {
      color: var(--swatch7);
      font-weight: 900;
    }
  }

  //textarea {
  //  height: 200px;
  //}

  .title {
    color: var(--swatch1);
    font-size: 20px;
    font-weight: 900;
    margin-bottom: 10px;

    a {
      font-style: italic;
      text-decoration: var(--swatch1) underline;
    }

    a:hover {
      cursor: pointer;
    }
  }

  .title-section {
    align-items: center;
    display: flex;
    flex-direction: row;

    .name {
      font-size: 18px;
      margin-left: 10px;
    }

    .title-label {
      color: var(--swatch1);
      font-size: 18px;
      font-weight: 900;
    }
  }

  .editor__editable,
  main .ck-editor[role='application'] .ck.ck-content,
  .ck.editor__editable[role='textbox'],
  .ck.ck-editor__editable[role='textbox'],
  .ck.editor[role='textbox'] {
    border: 1px solid var(--ck-color-toolbar-border) !important;
    margin-bottom: 2px;
    min-height: 300px;
    padding: 1em !important;
  }

  .leaflet-tooltip {
    position: absolute;
    padding: 0px !important;
    background-color: transparent !important;
    border: none !important;
    color: #144c14 !important;
    white-space: nowrap !important;
  }